<template>
  <v-container fluid>
    <!--  -->
    <base-show
      title="Edit Area"
      icoon="mdi-chart-bar-stacked"
    />
  </v-container>
</template>

<script>

  export default {
    name: 'Edit',
  }
</script>

<style></style>
